<template>
  <div class="md:sidebar-left relative bg-white px-2 md:-mx-4 md:py-6 lg:px-4 lg:py-8">
    <ul class="flex text-lg lg:hidden">
      <template v-for="(sector, index) in sectoren">
        <li
          v-if="index <= 1"
          :key="sector.title"
          :class="{'edgeLine': index === 1}"
          class="w-1/3 truncate p-2 sm:px-4 md:w-auto"
        >
          <nuxt-link
            :to="{name: 'materialen', query: {sector: sector.title}}"
            class="font-semibold text-grey-dark hover:no-underline focus:no-underline"
          >
            <font-awesome-icon
              v-if="sector.icon"
              :icon="sector.icon.split(/-(.+)/).slice(0, 2)"
              class="mr-2 md:mr-3"
            />
            {{ sector.title }}
          </nuxt-link>
        </li>
      </template>
      <li
        class="edgeLine flex-grow p-2 font-semibold sm:px-4"
        @click="openSidebar = true"
      >
        <a
          href="#"
          class="w-full truncate text-lg text-grey-dark hover:no-underline focus:no-underline"
        >
          Meer sectoren
          <font-awesome-icon
            :icon="['fal', 'chevron-right']"
            class="ml-2 md:ml-3"
          />
        </a>
      </li>
    </ul>
    <transition name="fade">
      <div
        v-if="openSidebar"
        class="relative inset-x-0 z-10 size-full overflow-auto bg-white py-8 text-center md:inset-auto md:px-4 md:text-left lg:relative lg:inline-block lg:p-0"
      >
        <button
          class="absolute right-0 top-0 mr-4 mt-4 text-2xl leading-none lg:hidden"
          aria-label="Sluit venster"
          @click="openSidebar = false"
        >
          <font-awesome-icon
            :icon="['fal', 'times']"
            aria-hidden="true"
          />
        </button>
        <h2 class="text-xl font-semibold uppercase">
          Sectoren
        </h2>
        <ul class="mb-12 text-xl">
          <li
            v-for="sector in sectoren"
            :key="sector.title"
            class="px-4 pt-5 md:px-0"
          >
            <nuxt-link
              :to="{name: 'materialen', query: {sector: sector.title}}"
              class="mr-3 flex items-center text-grey-dark hover:text-primary hover:no-underline"
            >
              <font-awesome-icon
                v-if="sector.icon"
                :icon="sector.icon.split(/-(.+)/).slice(0, 2)"
                fixed-width
              />
              <span class="ml-4 font-semibold leading-none">
                {{ sector.title }}
              </span>
            </nuxt-link>
          </li>
        </ul>
        <h2 class="text-xl font-semibold uppercase leading-7">
          Categorieën
        </h2>
        <ul class="mb-12">
          <li
            v-for="category in (showAllCategories ? categories : categories.slice(0, 10))"
            :key="category.name"
            class="px-4 pt-4 md:px-0"
          >
            <nuxt-link
              :to="`/materialen/${category.slug}`"
              class="text-md mr-3 flex items-center font-semibold text-grey-dark hover:text-primary hover:no-underline"
            >
              <font-awesome-icon
                :icon="['fal', 'chevron-right']"
                size="xs"
              />
              <span class="ml-4 w-full truncate text-left">
                {{ category.name }}
              </span>
              <span class="text-black text-opacity-25">
                ({{ category.product_count }})
              </span>
            </nuxt-link>
          </li>
          <li class="px-4 pt-4 md:px-0">
            <button
              class="smooth-transition border-b-2 font-semibold text-primary hover:text-primary-light"
              @click="showAllCategories = !showAllCategories"
            >
              Toon {{ showAllCategories ? 'minder' : 'alle' }} categorieën
            </button>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
const {isMobileOrTablet} = useDevice();

const openSidebar = ref(false);
const showAllCategories = ref(false);

onMounted(() => {
  openSidebar.value = !isMobileOrTablet;
});

const {result: categoriesResult} = useMainCategoriesQuery();
const categories = computed(() => categoriesResult.value?.mainCategories ?? []);

const {result: sectorenResult} = useSectorenQuery();
const sectoren = computed(() => sectorenResult.value?.sectoren ?? []);
</script>
