<template>
  <div class="sidebar-left relative bg-white md:py-6 lg:py-8">
    <button
      class="relative p-4 font-semibold text-grey-dark hover:no-underline focus:no-underline md:px-8 lg:hidden"
      @click="openSidebar = true"
    >
      Dashboard
      <font-awesome-icon
        :icon="['fal', 'chevron-right']"
        class="ml-3"
        size="sm"
      />
    </button>
    <transition name="fade">
      <div
        v-if="openSidebar && user"
        class="fixed inset-0 z-10 bg-white px-4 py-24 text-center lg:relative lg:inset-auto lg:inline-block lg:p-0 lg:text-left"
      >
        <span
          class="absolute right-0 top-0 mr-4 mt-16 text-2xl leading-none lg:hidden"
          @click="closeSidebar"
        >
          <font-awesome-icon
            :icon="['fal', 'times']"
            size="sm"
          />
        </span>

        <FormKit
          v-if="user.organisaties.length > 1"
          v-model.number="organisationId"
          type="select"
          label="Organisatie"
          :options="user.organisaties.map(organisation => ({
            value: organisation.id,
            label: organisation.title,
          })).sort((a, b) => a.label.localeCompare(b.label))"
        />

        <NuxtLink
          v-if="organisation && organisation.active_image && organisation.slug"
          :to="{name: 'aanbieders-slug', params: {slug: organisation.slug}}"
          class="block"
        >
          <UiImg
            v-if="organisation.active_image"
            :image="organisation.active_image"
            :alt="organisation.title"
            class="mt-4 hidden h-auto w-64 rounded object-contain lg:block"
          />
        </NuxtLink>

        <div
          v-if="organisation && organisation.slug"
          class="mt-6"
        >
          <nuxt-link
            :to="{name: 'aanbieders-slug', params: {slug: organisation.slug}}"
            class="smooth-transition hidden cursor-pointer rounded-md bg-secondary px-4 py-2 pr-5 text-left text-white hover:bg-secondary-light hover:no-underline lg:block"
            @click.stop="closeSidebar"
          >
            Naar organisatiepagina
          </nuxt-link>
        </div>
        <ul class="mb-12 mt-4">
          <template
            v-for="link in dashboardLinks"
            :key="link.id"
          >
            <li
              v-if="(!link.needsRole || user.roles.length > 0) && (!link.disabled || link.greyOut)"
              class="pt-1"
              @click="closeSidebar"
            >
              <nuxt-link
                :class="[
                  (link.link === $route.path) ? 'text-primary lg:bg-primary-lighter' : 'text-primary lg:text-grey-dark',
                  link.disabled ? 'cursor-default opacity-50' : 'hover:text-primary lg:hover:bg-primary-lighter'
                ]"
                class="text-md mr-3 flex items-center rounded-full p-2 px-3 font-semibold duration-100 hover:no-underline"
                :to="link.disabled ? '' : link.link"
              >
                <font-awesome-icon
                  v-if="link.icon"
                  :icon="link.icon"
                  size="sm"
                />
                <span class="ml-4 w-full truncate text-left">
                  {{ link.title }}
                </span>
                <font-awesome-icon
                  :icon="['fal', 'chevron-right']"
                  size="sm"
                  class="lg:opacity-0"
                />
              </nuxt-link>
              <separate class="mt-1 lg:hidden" />
            </li>
          </template>
        </ul>
        <div class="flex flex-col px-4 text-white md:w-1/2 lg:w-full lg:pl-0 lg:pr-8">
          <nuxt-link
            v-if="user.roles.length > 0 && isProjectManager"
            to="/dashboard/projecten/nieuw"
            class="btn btn-secondary mb-6"
            @click.stop="closeSidebar"
          >
            Nieuw project
          </nuxt-link>
          <nuxt-link
            v-if="user.roles.length > 0 && isMaterialManager"
            to="/dashboard/materialen/nieuw"
            class="btn btn-secondary"
            @click.stop="closeSidebar"
          >
            Nieuw materiaal
          </nuxt-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
const user = useSofieUserWhenAuthenticated();

const openSidebar = ref(true);

onMounted(() => {
  openSidebar.value = window.innerWidth > 769;
  window.addEventListener('resize', () => {
    openSidebar.value = window.innerWidth > 769;
  });
});

function closeSidebar() {
  if (import.meta.client && window.innerWidth < 1024) {
    openSidebar.value = false;
  }
}

const {result} = useMyOrganisationsQuery();
const {isAdmin, isProjectManager, isMaterialManager} = useOrganisationAccess();
const {isProSubscription, isProConnectSubscription, isProOrBetterSubscription} = useSubscriptionDetails();
const myOrganisations = computed(() => result.value?.myOrganisations ?? []);

const {organisationId, organisation} = useSelectedOrganisation();

const dashboardLinks = computed(() => {
  return [
    {
      title: 'Reacties',
      link: '/dashboard',
      needsRole: false,
      icon: ['fal', 'tachometer-alt'],
      disabled: !isProjectManager.value,
    },
    {
      title: 'Profiel',
      link: '/dashboard/profiel',
      needsRole: false,
      icon: ['fal', 'user'],
    },
    {
      title: 'Projecten',
      link: '/dashboard/projecten',
      needsRole: true,
      icon: ['fal', 'clipboard'],
      disabled: !myOrganisations.value.length || !isMaterialManager.value,
    },
    {
      title: 'Materialen',
      link: '/dashboard/materialen',
      needsRole: true,
      icon: ['fal', 'box'],
      disabled: !myOrganisations.value.length,
    },
    {
      title: 'Gebruikers',
      link: '/dashboard/gebruikers',
      needsRole: true,
      icon: ['fal', 'users'],
      disabled: !isProjectManager.value,
    },
    {
      title: 'Favorieten',
      link: '/dashboard/favorieten',
      needsRole: true,
      icon: ['fal', 'heart'],
    },
    {
      title: 'Zoekopdrachten',
      link: '/dashboard/zoekopdrachten',
      needsRole: false,
      icon: ['fal', 'search-plus'],
    },
    {
      title: 'Widget',
      link: '/dashboard/widget',
      needsRole: true,
      icon: ['fal', 'qrcode'],
      disabled: !(isProSubscription.value || isProConnectSubscription.value) || !isAdmin.value,
      greyOut: true,
    },
    {
      title: 'Importeren',
      link: '/dashboard/import',
      needsRole: true,
      icon: ['fal', 'file-import'],
      disabled: !organisation.value?.arc_gis_import || !isProOrBetterSubscription.value || !isAdmin.value,
      greyOut: true,
    },
  ];
});
</script>
