<template>
  <div>
    <app-header />
    <div class="min-h-screen bg-background">
      <div class="mx-auto grid grid-cols-4 lg:container md:px-4">
        <sidebar-dashboard
          v-if="route.name.includes('dashboard')"
          class="col-span-4 lg:col-span-1"
        />
        <sidebar
          v-else
          class="col-span-4 lg:col-span-1"
        />
        <div class="col-span-4 px-4 pb-20 sm:px-8 lg:col-span-3 lg:px-0 lg:pb-40 lg:pl-12">
          <slot />
        </div>
      </div>
    </div>
    <cookie-statement />
    <search-sector-code />
    <review ref="reviewModal" />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
</script>

<style>
.sidebar-left::before {
  @apply bg-white block absolute top-0 bottom-0 right-0;
  content: '';
  left: -9999px;
}

.cookieControl__ControlButton {
  bottom: 86px !important;
  @screen lg {
    bottom: 20px !important;
  }
}
</style>
